
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        






































































































.did-you-know-outer {
  @include bg-color;
}

.did-you-know,
[class*='did-you-know--'] {
  padding-top: $spacing * 4;
  padding-bottom: $spacing * 4;

  @include mq(m) {
    display: flex;
  }
}

.did-you-know__intro {
  @include mq(m) {
    width: col(5);
  }
}

.did-you-know__intro__icon {
  fill: $c-pink-medium;
}

.did-you-know__intro__title {
  @extend %fw-medium;
  margin-top: 0;
  padding-right: 5rem;
}

.did-you-know__fact {
  @include mq(m) {
    width: col(7);
  }
}

.did-you-know__links-outer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: $spacing;
}

.did-you-know__links {
  margin-top: $spacing;
  font-size: 1.5rem;
}
