
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        
































































































.product-net-outer,
[class*='product-net-outer--'] {
  @include bg-color;

  position: relative;
  padding: $spacing * 2 0;
  //DEV
  // margin-top: 120vh;

  @include mq('m') {
    padding: $spacing * 3 0;
  }

  @include mq('xl') {
    padding: $spacing * 6 0;
  }
}

.product-net {
  @include mq(l) {
    display: flex;
    align-items: center;
  }
}

.product-net__wrapp {
  @include mq(l) {
    width: col(7);
    padding-right: col(1);
  }
}

.product-net__icon-outer {
  @extend %text-uppercase;

  display: flex;
  align-items: center;
}

.product-net__icon {
  width: 4rem;
  margin-right: $spacing;
}

.product-net__content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

.product-net__content__title {
  margin: 0;
  margin-top: $spacing;
}

.product-net__content__excerpt {
  @extend %fw-light;

  margin: 0;
  margin-top: $spacing * 1.5;
}

.product-net__content__terms {
  margin-top: $spacing * 1.5;
  font-size: 1.2rem;
}

.product-net__content__include-outer {
  margin-top: $spacing * 2;

  @include mq(m) {
    margin-top: $spacing * 2.5;
  }
}

.product-net__content__include__excerpt {
  @extend %fw-light;

  margin-top: $spacing / 2;

  @include mq(m) {
    margin-top: $spacing;
  }
}

.product-net__speelines {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 50%;
  height: 100%;
  margin-top: 0;

  @include mq($until: m) {
    display: none;
  }
}
