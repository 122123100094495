
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        































































































































.promo-banner,
[class*='promo-banner--'] {
  position: relative;
  padding: 3.4rem 4rem;
  background-color: $c-blue-medium;
  border-radius: 8px;

  &:hover,
  &:focus {
    .promo-banner__content__link {
      color: $c-pink-medium !important; //stylelint-disable declaration-no-important

      &::before {
        transform-origin: left;
        transform: scaleX(1);
      }
    }
  }

  @include mq(s) {
    max-width: 850px;
    margin: 0 auto;
  }
}

.promo-banner__box {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;

  [class*='promo-banner--'][class*='--generic'] & {
    display: block;

    @include mq(xs) {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }
  }
}

.promo-banner__promo {
  @extend %fw-bold;
  @extend %color-white;

  margin-bottom: $spacing;
  font-family: $ff-alt;

  ::v-deep strong {
    @extend %fw-ultra;

    display: block;
    font-size: 4.8rem;
    line-height: 1;
  }

  @include mq(xs) {
    margin: 0;
    text-align: center;
    text-transform: uppercase;

    [class*='promo-banner--'][class*='--discount'] & {
      font-size: 7rem;
    }
  }
}

.promo-banner__content {
  z-index: 3;

  @include mq(s) {
    max-width: 70%;

    [class*='promo-banner--'][class*='--discount'] & {
      margin-left: $spacing * 2;
      max-width: 60%;
    }
  }
}

.promo-banner__title {
  margin-top: 0;
  margin-bottom: 0.6rem;
  color: $white;

  strong {
    color: $c-pink-medium;
  }

  [class*='promo-banner--'][class*='--b2s'] & {
    @extend %fw-bold;

    display: block;
    font-size: 2.8rem;
    line-height: 1.15;

    @include mq(l) {
      font-size: 2.8rem;
    }
  }

  @include mq(l) {
    font-size: 3em / $body-text-l * 1em;
  }
}

.promo-banner__txt {
  margin-top: 0;
  margin-bottom: 0;
  color: $white;
  font-family: $ff-default;

  [class*='promo-banner--'][class*='--b2s'] & {
    @extend %fw-bold;

    display: block;
    max-width: 28rem;
    margin-top: 1rem;
    color: $white;
    font-family: $ff-alt;
    font-size: 1.8rem;
    line-height: 1.2;
  }

  .offers-packs & {
    @extend %fw-medium;
  }
}

.promo-banner__content__link {
  margin-top: $spacing * 0.75;
  pointer-events: none;
}

.promo-banner__fig {
  display: none;

  @include mq(xs) {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    width: 6.7rem;
    margin: 0;
  }

  @include mq(s) {
    margin-left: 1.6rem;
  }
}

.promo-banner__icon {
  width: 100%;
  height: 100%;
  fill: $white;
}

.promo-banner__tag {
  position: absolute;
  z-index: 3;
  top: -15px;
  left: 2.8rem;

  strong {
    @extend %fw-bold;
  }

  @include mq('s') {
    left: 4.7rem;

    [class*='promo-banner--'][class*='--generic'] & {
      left: 4rem;
    }
  }
}

.promo-banner__image,
.promo-banner__video {
  @include get-all-space;

  // z-index: 2;

  img,
  video {
    @include image-fit;

    border-radius: 8px;

    [class*='--full'] & {
      @include get-all-space;

      object-fit: cover;
      border-radius: 0;
    }
  }

  @include mq($until: m) {
    display: none;
  }
}

.promo-banner__link {
  @include get-all-space;

  z-index: 4;
  cursor: pointer;
}

.promo-banner__speedlines {
  @include center-y;

  z-index: 1;
  width: 100%;
  height: 140%;
}

.promo-banner__btn {
  margin-top: $spacing * 0.75;
}
