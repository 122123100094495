
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        













































































































































































.pack__devices {
  color: $white;
  background-color: $c-blue-dark;
}

.compatible-devices__label {
  margin-bottom: $spacing * 1.5;
  font-size: 1.8rem;
  font-weight: 700;
}

.compatible-devices__logos {
  display: flex;
}

.compatible-devices__logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: $spacing * 2.25;

  .icon {
    height: 3rem;
    margin-bottom: $spacing / 2;
    fill: $white;
    transition: fill 0.2s $ease-out-quart;
  }

  .text {
    color: $white;
    font-size: 1.1rem;
    font-weight: 900;
    transition: color 0.2s $ease-out-quart;
  }

  &:hover {
    .icon {
      fill: $c-pink-medium;
    }

    .text {
      color: $c-pink-medium;
    }
  }
}
